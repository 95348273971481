.ToolsPicker_sliderBackground{
    position: absolute;
    top: 8px;
    left: -31px;
    width: 0px;
    height: 0px;
    border-top: 3px solid #ffffff00;
    border-bottom: 3px solid #ffffff00;
    border-left: 0px solid #ffffff00;
    border-right: 195px solid gray;
}
.ToolsPicker_slider{
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0px;
    background-color: #ececec00;
    /* box-shadow: 0px 0px 1px 1px #00000042; */
    outline: none;
    transition: background-color 0.2s ease-in-out;
    z-index: 10;
}
.ToolsPicker_sliderContainer{
    position: relative;
    overflow: hidden;
    width: 164px;
    height: 23px;
    background: #383838;

    margin: 10px 0px;
    border-radius: 7px;
}
.ToolsPicker_sliderContainer:hover {
    background-color: #2d2d2d;
}
.ToolsPicker_slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 5px;
    height: 25px;
    border-radius: 0px;
    background: #ffffff88;
    box-shadow: 0px 0px 1px black;
    cursor: pointer;
  }
  
