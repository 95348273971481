body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1{
  padding: 0.67em 0;
  margin: 0;
}
h2{
  padding: 0.83em 0;
  margin: 0;
}
h3{
  padding: 1em 0;
  margin: 0;
}
h4{
  padding: 1.33em 0;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.overlay{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000e0;
}
.overlay-input-container{
  position: fixed;
    background: #fff9f9;
    padding: 7px 9px;
    border: 1px solid #d6d6d6;
    box-shadow: rgb(0 0 0 / 3%) 0px 0px 0px 1px, rgb(0 0 0 / 12%) 0px 3px 6px, rgb(0 0 0 / 8%) 0px 9px 24px;
    z-index: 123;
    color: white;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.overlay-input {
  border: none;
  height: 23px;
  background: #fff9f9;
  width: 100%;
  padding: 0px 6px;
  border-radius: 5px;
}
.overlay-input:focus {
  outline: none;
}
.overlay-input-submit {
  cursor: pointer;
  background: #848484;
  font-size: 0.8em;
  padding: 1px 8px;
  text-align: left;
  border-radius: 4px;
  line-height: 24px;
  margin: 0px 0px 0px 6px;
}
.editableContent:focus{
  outline: none;

}

.editableContent {
  overflow-wrap: break-word;
  -webkit-white-spac: break-spaces;
  white-space: break-spaces;
  height: 252px;
  cursor: text;
}
button{
  cursor: pointer;
}