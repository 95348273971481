.SelectionList_list {
    max-width: 278px;
    border-radius: 5px;
    /* position: absolute; */
    background: white;
    font-size: 14px;
    padding: 6px 0px;
    box-shadow: 0px 0px 14px 1px #1211113b;
    color: #191616;
}

.SelectionList_item {
    display: flex;
    justify-content: space-between;
    padding: 9px 14px 9px  24px;
}

.SelectionList_value {
    color: #849c84;
}
.SelectionList_item.selected {
    background: #bde2cf;
}
.SelectionList_match{
    color:rebeccapurple;
}