
.ToolsMenu_color{
    border: solid 2px rgba(231, 231, 231, 0.459);
}
.active .ToolsMenu_color {
    border: solid 2px rgba(170, 170, 170, 0.555);
}
.btn.active div{
    border-radius: 0px !important;
}
.btn div{
    position: absolute;
    border-radius: 50%;
}
.btn{
    position: absolute;
    text-align: center;
    opacity: 0;
    transition: border-radius .2s,top .2s,left .2s,right 0.2s, opacity 0.2s;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
}

.btn svg{
    color: #000000;
    display: block;
    margin: auto;
    width: 15px ;
    height: 15px ;
}
.btn:hover {
    border-color: #80808078;
    border-radius: 1px;
}
#circle1{
    position: absolute;
    width: 170px;
    height: 170px;
    border-color: rgb(43, 43, 43);
    border-style: solid;
    border-width: 0px;
    border-radius: 50%;
    transition: transform 0.2s;
    transform: scale(0);
}
#toggle{
    position: absolute;
    background: #2f2f2f;
    border-radius: 50%;
    z-index: 100;
}
#menu{
    position: absolute;
}
.menu{
    position: absolute;
    left: 50%;
    top: 50%;
    border-color: rgb(43, 43, 43);
    border-style: solid;
    border-width: 0px;
    border-radius: 50%;

}