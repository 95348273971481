.ContentList_add{
    display: flex;
    align-items: center;
    color: #363030;
    padding: 6px 12px;
    cursor: pointer;
    background: #efefef;
    margin: 20px 53px;
    width: 54px;
    border-radius: 8px;
    justify-content: space-around;
}
.ContentList_add:hover{
    background: #7a7a7a;
    color: #ebebeb;
}