#colorPicker{
    user-select: none;
    flex-direction: row;
    flex-wrap: wrap;
    background: #1b1b1b;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    user-select: none;
}
#colorPicker>div{
    margin: 0px 0px 8px;
}
#colorSelector{
    position: relative;
    height: 25px;
    width: 100%;
    margin: 0px 0px;
    background: linear-gradient(90deg,#ff0000,#ffff00,#00ff00,#00ffff 50%,#0000ff,#ff00ff, #ff0000);
}
#alphaSelectorContainer{
    width: 100%;
    height: 25px;
    margin: 0px 0px;
    border-left: none;
}
#blackwhiteSelector{
    width: 200px;
    height: 200px;
    position: relative;
}
.ColorPicker_layers div{
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.ColorPicker_whiteTransparent{
    background: linear-gradient(90deg,#fff,#ffffff00);
}
.ColorPicker_transparentBlack{
    background: linear-gradient(180deg,#ffffff00,#000);
}
.transparentBackground{
    background-image: 
    linear-gradient(45deg, rgb(201, 201, 201) 25%, transparent 25%, transparent 75%, rgb(201, 201, 201) 75%),
    linear-gradient(45deg, rgb(201, 201, 201) 25%, white 25%, white 75%, rgb(201, 201, 201) 75%);
    background-size: 8px 8px;
    background-position: 0 0, 4px 4px;
}
.ColorPicker_alphaSelector{
    width: 100%;
    height: 100%;    
    background: linear-gradient(90deg,transparent, #fff);
    position: relative;
}
.ColorPicker_sliderContainer{
    position: relative;
    height: 100%;
}
.ColorPicker_slider{    
    position: absolute;
    width: 3px;
    left: -1.5px;
    height: 100%;
    background: #ffffff8f;
    pointer-events: none;
    z-index: 100;
    box-shadow: 0px 0px 2px 0px #000000d9;
}
.ColorPicker_dotContainer{
    position: absolute;
}
.ColorPicker_dot {
    position: absolute;
    left: -5px;
    top: -5px;
    width: 8px;
    height: 8px;
    background: #ffffff00;
    border-radius: 50%;
    pointer-events: none;
    z-index: 100;
    border: solid 1px white;
    box-shadow: 0px 0px 6px 1px #00000066;
}
