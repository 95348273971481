
.PageView_sidebar {
    width: 300px;
    background: #1c1c20;
}

.PageView_container {
    display: flex;
    height: 100vh;
}

.PageView_page {flex: 1 0 auto;
    height: 100%;
    overflow-y: scroll;
}

.PageView_sidebarItem {
    display: flex;
    justify-content: space-between;
    background: #1c1c20;
    color: #bfbfbf;
    margin: 9px 0px;
}
.PageView_sidebarItemName {
    cursor: pointer;
    padding: 13px 12px 13px 52px;
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.PageView_sidebarItemNameEdit{
    width: 100%;
    border: none;
    height: 100%;
    background: #ffffff;
    color: #39383a;
    border-radius: 3px;
    font-size: inherit;
}
input.PageView_sidebarItemNameEdit:focus { 
    
    outline: none;
}
.PageView_sidebarItemEdit{
    display: none;
}
.PageView_sidebarItem:hover .PageView_sidebarItemEdit{
    display: block;
}
.PageView_sideBarItemEditIconR:hover{
    color: #289ecc;
}
.PageView_sideBarItemEditIconD:hover{
    color: #ff7171;
}
.PageView_sidebarItem:hover{
    background: #ffffffc9;
    color: black;
}
.PageView_sideBarItemEditIcon {
    cursor: pointer;
    font-size: 18px;
    height: 100%;
    padding: 0px 9px;
    margin: 0 6px 0px 0px;
}
.PageView_sideBarItemDelete {
    display: flex;
    align-items: center;
    height: 100%;
}
.PageView_sideBarItemDelete div{
    cursor: pointer;
    border-radius: 3px;
    padding: 6px 8px;
    color: #fbf7f7;
    margin: 4px;
}
.PageView_sideBarItemDeleteComfirm {
    background: #ff8787;

}
.PageView_sideBarItemDeleteCancel {
    background: #444444;
}
button {
    width: 100%;
    background: #36303059;
    border: none;
    border-radius: 5px;
    /* height: 23px; */
    color: #ffffffb8;
    margin: 9px 0px;
    padding: 10px;
}
button:hover {
    background: #ffffffc9;
    color: black;
}
.selected.PageView_sidebarItem {
    background: #1d303e;
    color: #ffffffb8;
}