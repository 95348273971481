.Block_burger{
    position: absolute;
    left: 20px;
    top: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    color:#424246;
    opacity: 0;
}
.Block_container{
    padding: 0px 55px;
    position: relative;
}
.Block_container:hover .Block_burger{
    opacity: 1;
}
.Block_container:hover .EditableContent{
    background: #fdfbf5;
}